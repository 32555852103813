<template>
  <SlDialog
    title="收货"
    :visible.sync="show"
    :width="dialogWidth"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :lock-scroll="false"
    :append-to-body="true"
    :before-close="close"
    @cancel="close"
    @closed="closed"
  >
    <div v-loading="loading">
      <div class="logistics-main mb-2rem">
        <el-row class="logistics-main--content">
          <el-col
            v-for="(item, index) in props"
            :span="12"
            :key="item.prop"
            :class="{'mb-8px': [0, 1].includes(index)}"
          >
            <span class="line-height-20 prop-label mr-8px">{{item.label}}:</span>
            <span class="line-height-20 prop-value">{{baseInfo[item.prop]}}</span>
          </el-col>
        </el-row>
      </div>

      <el-form ref="form" :model="tableForm" label-width="0px">
        <SlTableInfo
          :isEmbedTable="true"
          :tableData="tableForm.tableData"
          :columns="columns"
          headerAlign="left"
          max-height="360"
        >
          <template #arrivalQuantity="{ row, index }">
            <template v-if="status === 0">
              <el-form-item
                label-width="0px"
                :prop="'tableData.' + index + '.arrivalQuantity'"
                :rules="rules.arrivalQuantity"
              >
                <el-input
                  v-model="row.arrivalQuantity"
                  v-slFormatNumber="numberFormat(row.deliveryQuantity)"
                  placeholder=">0"
                ></el-input>
              </el-form-item>
            </template>
            <template v-else>
              <span class="color-primary">{{row.arrivalQuantity}}</span>
            </template>
          </template>
        </SlTableInfo>
      </el-form>
    </div>
    <template #bottom>
      <template v-if="status === 0">
        <el-button @click="close">{{$t('button.cancelText')}}</el-button>
        <el-button
          type="primary"
          :loading="handleLoading"
          :disabled="handleLoading"
          @click="handleSubmit"
        >{{$t('button.enterText')}}</el-button>
      </template>
      <template v-else>
        <el-button @click="close">关闭</el-button>
      </template>
    </template>
  </SlDialog>
</template>
<script>
import OemGoodsAPI from '@api/oemGoods'
import OemInventory from '@api/oemInventory'
const commonColumn = [
  {
    name: 'purchaseOrderNumber',
    label: '生产订单号'
  },
  {
    name: 'deliveryOrderNumber',
    label: '发货单号'
  },
  {
    name: 'skuCode',
    label: '底版SKU'
  },
  {
    name: 'skuImage',
    label: '图片',
    isImage: true,
    data: {
      imgSize: '6rem'
    }
  },
  {
    name: 'goodsName',
    label: '商品名称',
    render: (h, scope) => {
      let { row = {} } = scope
      return (
        <div>
          <p>{row.goodsName}</p>
          <p>{row.attributesName}</p>
        </div>
      )
    }
  },
  {
    name: 'deliveryQuantity',
    label: '实际发货数量'
  }
]
export default {
  name: 'ReceivingDialog',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    numberFormat () {
      return function (max) {
        return { type: 'integer', max, compareLength: false, includeZero: false }
      }
    },
    status () {
      return this.baseInfo && this.baseInfo.status
    },
    columns () {
      const extraColumn = [{
        name: 'arrivalQuantity',
        label: '到货数量'
      }]
      return this.status === 2 ? commonColumn.slice() : commonColumn.concat(extraColumn)
    }
  },
  data () {
    return {
      loading: false,
      handleLoading: false,
      dialogWidth: '60%',
      baseInfo: {},
      tableForm: {
        tableData: []
      },
      props: [
        {
          prop: 'orderNumber',
          label: '装箱单号'
        },
        {
          prop: 'supplierName',
          label: '底版供应商'
        },
        {
          prop: 'deliveryAt',
          label: '发货时间'
        },
        {
          prop: 'statusName',
          label: '状态'
        }
      ],
      rules: {
        arrivalQuantity: [
          {
            required: true,
            message: '请输入到货数量',
            triggger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    handleSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.handleLoading = true
          // oem-goods 与 inventory 对应不上
          const submitData = {
            consignerId: this.baseInfo.supplierId,
            consignerName: this.baseInfo.supplierName,
            deliveryOrderPackageNumber: this.baseInfo.orderNumber,
            logisticsBillNumber: this.baseInfo.logisticsNumber,
            receiveSkus: []
          }
          submitData.receiveSkus = this.tableForm.tableData.map(item => ({
            actualQuantity: item.deliveryQuantity,
            deliveryOrderNumber: item.deliveryOrderNumber,
            purchaseOrderNumber: item.purchaseOrderNumber,
            receiveQuantity: item.arrivalQuantity,
            sku: {
              attributes: item.attributesName,
              productName: item.goodsName,
              skuCode: item.skuCode,
              skuId: item.skuId,
              skuImage: item.skuImage,
              spuCode: item.spuCode
            }
          }))
          OemInventory.receive(submitData).then(res => {
            if (res.success) {
              if (Array.isArray(res.data) && res.data.length) {
                let message = '<p class="el-message__content mb-16px">收货结果：</p>'
                res.data.forEach(item => {
                  message += `<p class="mb-8px">${item}</p>`
                })
                this.$message({
                  showClose: true,
                  type: 'warning',
                  duration: 0,
                  dangerouslyUseHTMLString: true,
                  message: message
                })
              } else {
                this.$message.success('收货成功！')
              }
              this.$emit('submited')
              this.close()
            }
          }).catch(() => {
            this.handleLoading = false
          })
        }
      })
    },
    close () {
      this.$emit('update:show', false)
    },
    closed () {
      this.handleLoading = false
      this.$refs.form.resetFields()
      this.tableForm.tableData = []
    }
  },
  watch: {
    show (bool) {
      if (bool) {
        const status = this.row.status
        OemGoodsAPI.statisticByPackageDetail({ id: this.row.id }).then(res => {
          if (res.success) {
            this.baseInfo = res.data || {}
            const { waitForReceivingList = [] } = this.baseInfo
            if (this.baseInfo.status === 0) {
              waitForReceivingList.forEach(item => {
                item.arrivalQuantity = '' // 处理回显0的问题
              })
            } else {
              // 待收货单号已经变成了已收货
              if (status === 0) {
                this.$message.warning('装箱单状态已变更，无需再收货！')
              }
            }
            this.tableForm.tableData = waitForReceivingList
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import '@assets/scss/_var.scss';
.line-height-20 {
  line-height: 2rem;
}

.logistics-main {
  border: 1px solid #edf0f2;
  .logistics-main--header {
    padding-left: 1rem;
    line-height: 4rem;
    background-color: $color-table-header-bg;
  }

  .logistics-main--content {
    padding: 1rem;
  }
}

.prop-label {
  color: $color-text-primary;
}

.prop-value {
  color: $color-text-minor;
}
.color-primary {
  color: $color-primary;
}
</style>
