<template>
  <div class="pending-delivery">
    <SlSwitchNav :navs="switchNavs" :default-active="activeNav" @select="switchNav"></SlSwitchNav>

    <SlListView
      @gotoPage="gotoPage"
      :total="page.total"
      :pageIndex="page.pageIndex"
      :pageSize="page.pageSize"
    >
      <div solt="search">
        <SlSearchForm
          v-model="query"
          :items="searchItems"
          :loading="tableLoading"
          @reset="gotoPage(page.pageSize)"
          @search="gotoPage(page.pageSize)"
        ></SlSearchForm>

        <SlTable
          :tableData="tableData"
          :columns="columns"
          :operate="true"
          :tooltip="false"
          :selection="false"
          align="left"
          rowKey="id"
        >
          <div slot="operation" slot-scope="{row}">
            <el-button class="operation-btn" type="text" @click="onReceive(row)">{{operationName}}</el-button>
          </div>
        </SlTable>
      </div>
    </SlListView>
    <!-- 收货/查看 Dialog -->
    <ReceivingDialog :show.sync="visible" :row="row" @submited="gotoPage" />
  </div>
</template>

<script>
import OemGoodsAPI from '@api/oemGoods'
import ReceivingDialog from './pendingReceiving/receivingDialog.vue'

export default {
  name: 'PendingReceiving',
  components: {
    ReceivingDialog
  },
  computed: {
    operationName () {
      return this.activeNav === '0' ? '收货' : '查看'
    }
  },
  data () {
    return {
      switchNavs: [
        {
          tabName: '待收货',
          tabType: '0'
        }, {
          tabName: '已收货',
          tabType: '1'
        }, {
          tabName: '已取消',
          tabType: '2'
        }
      ],
      activeNav: '0',
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      query: {},
      searchItems: [
        {
          type: 'input',
          label: '装箱单号',
          name: 'deliveryOrderPackageNumber'
        },
        {
          type: 'input',
          label: '底版供应商',
          name: 'basePlateSupplierName'
        },
        {
          type: 'date',
          label: '发货时间',
          name: 'createAts',
          data: {
            datetype: 'daterange',
            isBlock: true
          }
        }
      ],
      tableLoading: false,
      tableData: [],
      columns: [
        {
          prop: 'orderNumber',
          label: '装箱单号'
        }, {
          prop: 'skuQuantity',
          label: 'SKU种类'
        }, {
          prop: 'deliveryQuantity',
          label: '发货总件数(PCS)'
        }, {
          prop: 'logisticsCompanyName',
          label: '物流商'
        }, {
          prop: 'logisticsNumber',
          label: '物流单号'
        }, {
          prop: 'statusName',
          label: '状态'
        }, {
          prop: 'supplierName',
          label: '底版供应商'
        }, {
          prop: 'deliveryAt',
          label: '发货时间'
        }
      ],
      // dialog
      visible: false,
      row: {}
    }
  },
  methods: {
    switchNav (type) {
      this.activeNav = type
      this.gotoPage()
    },
    gotoPage (pageSize = 10, pageIndex = 1) {
      const params = this.generateParams(pageSize, pageIndex)
      this.tableLoading = true
      OemGoodsAPI.statisticByPackage(params).then(res => {
        if (res.success) {
          const data = res.data || {}
          this.tableData = data.list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    generateParams (pageSize, pageIndex) {
      let { createAts = [], ...orther } = this.query
      return {
        deliveryOrderPackageStatus: this.activeNav,
        ...orther,
        pageIndex,
        pageSize,
        deliveryAtStart: createAts && createAts[0] ? createAts[0] : '',
        deliveryAtEnd: createAts && createAts[1] ? createAts[1] : ''
      }
    },
    onReceive (row) {
      this.visible = true
      this.row = row
    }
  }
}
</script>

<style scoped lang="scss">
.operation-btn {
  margin-left: 0 !important;
  margin-right: 0.8rem;
}
</style>
